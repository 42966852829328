import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b63ca5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imprint" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PublicHeader = _resolveComponent("PublicHeader")!
  const _component_vue_markdown = _resolveComponent("vue-markdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PublicHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('shared.view.imprint.imprint')), 1),
      _createVNode(_component_vue_markdown, {
        source: _ctx.imprint.imprint
      }, null, 8, ["source"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('shared.view.imprint.owner')), 1),
      _createVNode(_component_vue_markdown, {
        source: _ctx.imprint.owner
      }, null, 8, ["source"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('shared.view.imprint.rights')), 1),
      _createVNode(_component_vue_markdown, {
        source: _ctx.imprint.rights
      }, null, 8, ["source"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('shared.view.imprint.support')), 1),
      _createVNode(_component_vue_markdown, {
        source: _ctx.imprint.support
      }, null, 8, ["source"]),
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('shared.view.imprint.liability')), 1),
      _createVNode(_component_vue_markdown, {
        source: _ctx.imprint.liability
      }, null, 8, ["source"])
    ]),
    _createElementVNode("footer", null, [
      _createElementVNode("footer", null, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/`)))
        }, _toDisplayString(_ctx.$t('shared.view.imprint.home')), 1)
      ])
    ])
  ], 64))
}