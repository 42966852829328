import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FromSubmitItem = _resolveComponent("FromSubmitItem")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "dataForm",
    model: _ctx.formData,
    "label-position": "top",
    "status-icon": true,
    onValidate: _ctx.formItemValidated,
    onSubmit: _ctx.submitData,
    onReset: _ctx.reset
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.useDefaultSubmit)
        ? (_openBlock(), _createBlock(_component_FromSubmitItem, {
            key: 0,
            "form-state-message": _ctx.formData.stateMessage,
            "submit-label-key": _ctx.submitLabelKey
          }, null, 8, ["form-state-message", "submit-label-key"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "afterSubmit")
    ]),
    _: 3
  }, 8, ["model", "onValidate", "onSubmit", "onReset"]))
}