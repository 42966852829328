import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5da9bdab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm__content full-height-header" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "profile__email" }
const _hoisted_4 = {
  role: "button",
  class: "link"
}
const _hoisted_5 = {
  key: 1,
  class: "error"
}
const _hoisted_6 = { class: "profile__email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.confirmed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('moderator.view.confirmEmail.thanks')), 1),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('moderator.view.confirmEmail.info')) + " " + _toDisplayString(_ctx.email), 1),
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('moderator.view.confirmEmail.home')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.failed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('moderator.view.confirmEmail.failed')), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}