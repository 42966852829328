import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessTimeline = _resolveComponent("ProcessTimeline")!

  return (_openBlock(), _createBlock(_component_ProcessTimeline, {
    modelValue: _ctx.tasks,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tasks) = $event)),
    publicScreen: _ctx.publicTask,
    "onUpdate:publicScreen": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.publicTask) = $event)),
    activeItem: _ctx.editTask,
    "onUpdate:activeItem": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editTask) = $event)),
    publicScreenTopic: _ctx.publicScreenTopic,
    translationModuleName: "taskTimeline",
    entityName: _ctx.TimerEntity.TASK,
    readonly: _ctx.readonly,
    canDisablePublicTimeline: true,
    canUseOtherPublicScreenTopic: true,
    canClickHome: false,
    isLinkedToDetails: _ctx.isLinkedToTask,
    startParticipantOnPublicChange: false,
    keyPropertyName: "id",
    defaultTimerSeconds: null,
    authHeaderTyp: _ctx.authHeaderTyp,
    hasParticipantOption: (item) => _ctx.hasParticipantComponent[item.id],
    contentListIcon: _ctx.contentListIcon,
    contentListColor: _ctx.contentListColor,
    getKey: (item) => item.id,
    getTitle: (item) => (item.keywords ? item.keywords : item.name),
    getTimerEntity: (item) => item,
    itemIsEquals: (a, b) => (!a && !b) || (a && b && a.id === b.id),
    displayItem: (item) => item,
    darkMode: _ctx.darkMode,
    "has-home-state": true,
    "home-state": _ctx.shareStateValue,
    "onUpdate:homeState": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.shareStateValue) = $event)),
    onChangeOrder: _ctx.dragDone,
    onChangeActiveElement: _ctx.onEditTaskChanged,
    onChangePublicScreen: _ctx.onPublicTaskChanged
  }, null, 8, ["modelValue", "publicScreen", "activeItem", "publicScreenTopic", "entityName", "readonly", "isLinkedToDetails", "authHeaderTyp", "hasParticipantOption", "contentListIcon", "contentListColor", "getKey", "getTitle", "getTimerEntity", "itemIsEquals", "displayItem", "darkMode", "home-state", "onChangeOrder", "onChangeActiveElement", "onChangePublicScreen"]))
}