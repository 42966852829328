import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b649bcb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participant-background" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_header = _resolveComponent("el-header")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_container, {
      class: _normalizeClass(["participant-container full-height", _ctx.backgroundClass])
    }, {
      default: _withCtx(() => [
        (_ctx.drawNavigation)
          ? (_openBlock(), _createBlock(_component_el_header, {
              key: 0,
              class: _normalizeClass(["participant-header", {
          'participant-header__background': !_ctx.useFullSize && !_ctx.backgroundClass,
          'participant-header__fixedHead': _ctx.useFullSize,
        }])
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]),
              _: 3
            }, 8, ["class"]))
          : _createCommentVNode("", true),
        _createVNode(_component_el_main, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["class"])
  ]))
}