import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f9b194e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar__page-header" }
const _hoisted_2 = { class: "sidebar__logo" }
const _hoisted_3 = { class: "sidebar_icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "toggleSidebarButton link",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleVisible && _ctx.toggleVisible(...args)))
    }, [
      _createVNode(_component_font_awesome_icon, {
        icon: _ctx.displaySettings ? 'xmark' : 'bars'
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_font_awesome_icon, {
        icon: _ctx.getIconName(),
        class: "logo"
      }, null, 8, ["icon"])
    ]),
    _createElementVNode("span", _hoisted_3, [
      _createVNode(_component_router_link, { to: "/sessions" }, {
        default: _withCtx(() => [
          _createVNode(_component_ToolTip, {
            placement: 'bottom',
            text: _ctx.$t('moderator.organism.session.overview.header'),
            effect: 'light'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "home" })
            ]),
            _: 1
          }, 8, ["text"])
        ]),
        _: 1
      }),
      _createVNode(_component_router_link, { to: "/profile" }, {
        default: _withCtx(() => [
          _createVNode(_component_ToolTip, {
            placement: 'bottom',
            text: _ctx.$t('moderator.view.profile.header'),
            effect: 'light'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "user" })
            ]),
            _: 1
          }, 8, ["text"])
        ]),
        _: 1
      })
    ])
  ]))
}