import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f01d6cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__level" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.displayAvatar)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.avatar, (item) => {
            return (_openBlock(), _createElementBlock("span", {
              key: item.symbol,
              style: _normalizeStyle({ color: item.color })
            }, [
              _createVNode(_component_font_awesome_icon, {
                icon: item.symbol
              }, null, 8, ["icon"]),
              _createTextVNode("   ")
            ], 4))
          }), 128))
        ]))
      : (_ctx.color)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            style: _normalizeStyle({ color: _ctx.color })
          }, _toDisplayString(_ctx.text.toUpperCase()), 5))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_el_tooltip, {
              placement: "top",
              content: _ctx.text
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.text.toUpperCase()), 1)
              ]),
              _: 1
            }, 8, ["content"])
          ])),
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($event.cancelBubble = true))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}