import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3642e9b9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "timer"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_ctx.showTime)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.timeLeft !== null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.showTime ? _ctx.formattedTime : _ctx.$t('shared.atom.timer.inactive')), 1))
          : (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 1,
              icon: "infinity"
            }))
      ]))
    : _createCommentVNode("", true)
}