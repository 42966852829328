import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ae8b5a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "heading heading--regular line-break",
  style: {"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}
}
const _hoisted_2 = { class: "awesome-icon" }
const _hoisted_3 = {
  key: 0,
  class: "addOn__text"
}
const _hoisted_4 = {
  style: {"max-width":"calc(var(--app-width) * 0.5)"},
  class: "line-break"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "never",
    "body-style": "text-align: center",
    class: _normalizeClass({
      addOn__boarder: _ctx.isAddOn,
      selected: _ctx.selected,
    }),
    style: _normalizeStyle({
      '--module-color': _ctx.getColor(),
    }),
    onClick: _ctx.toggleSelection
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t(
          `module.${_ctx.moduleTask.taskType}.${_ctx.moduleTask.moduleName}.description.title`
        )), 1),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
              key: 0,
              icon: _ctx.icon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isAddOn)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('moderator.organism.cards.moduleCard.addOn')), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_el_tooltip, { placement: "top" }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(
              `module.${_ctx.moduleTask.taskType}.${_ctx.moduleTask.moduleName}.description.description`
            )), 1)
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", {
            class: _normalizeClass([{ twoLineText: !_ctx.isAddOn, oneLineText: _ctx.isAddOn }, "line-break"])
          }, _toDisplayString(_ctx.$t(
            `module.${_ctx.moduleTask.taskType}.${_ctx.moduleTask.moduleName}.description.description`
          )), 3)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "style", "onClick"]))
}