import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dc64d45"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout__level" }
const _hoisted_2 = { style: {"margin-right":"0"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createBlock(_component_ValidationForm, {
    "form-data": _ctx.formData,
    "use-default-submit": false,
    onSubmitDataValid: _ctx.save,
    onReset: _ctx.reset
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.showSettings,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSettings) = $event)),
        title: _ctx.$t('moderator.organism.settings.linkSettings.link'),
        "before-close": _ctx.handleClose,
        width: "calc(var(--app-width) * 0.8)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            prop: "editLink",
            rules: [_ctx.defaultFormRules.ruleUrl]
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.formData.editLink,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.editLink) = $event)),
                  name: "link",
                  autocomplete: "on",
                  placeholder: 
              _ctx.$t('moderator.organism.settings.linkSettings.linkExample')
            
                }, null, 8, ["modelValue", "placeholder"]),
                _createElementVNode("span", _hoisted_2, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    "native-type": "submit",
                    circle: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "check",
                        style: {"font-size":"1.5rem"}
                      })
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["rules"]),
          _createVNode(_component_el_form_item, {
            prop: "stateMessage",
            rules: [_ctx.defaultFormRules.ruleStateMessage]
          }, null, 8, ["rules"])
        ]),
        _: 1
      }, 8, ["modelValue", "title", "before-close"])
    ]),
    _: 1
  }, 8, ["form-data", "onSubmitDataValid", "onReset"]))
}