import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    prop: "stateMessage",
    rules: [_ctx.defaultFormRules.ruleStateMessage]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, {
        modelValue: _ctx.formStateMessage,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formStateMessage) = $event)),
        class: "hide",
        "input-style": "display: none"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_el_button, {
        "native-type": "submit",
        class: "fullwidth el-button--submit",
        disabled: _ctx.disabled,
        loading: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t(_ctx.submitLabelKey)), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading"])
    ]),
    _: 1
  }, 8, ["rules"]))
}