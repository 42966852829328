import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-840c85aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "session-code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TutorialStep = _resolveComponent("TutorialStep")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      type: _ctx.buttonType,
      class: "fullwidth stretch",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.code)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_font_awesome_icon, { icon: "clone" }),
        _createVNode(_component_TutorialStep, {
          type: "sessionDetails",
          step: "connectionCode",
          order: 1
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.code), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["type"]),
    _createVNode(_component_TutorialStep, {
      type: "sessionDetails",
      step: "shareCode",
      order: 2
    }, {
      default: _withCtx(() => [
        (_ctx.hasSharing)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: _ctx.buttonType,
              class: "session-code__share",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyToClipboard(_ctx.connectionLink)))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "share-alt" })
              ]),
              _: 1
            }, 8, ["type"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}