import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e1db5c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "vue-html2pdf" }
const _hoisted_2 = { class: "layout-container" }
const _hoisted_3 = {
  key: 0,
  class: "pdf-preview"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("section", {
        class: "content-wrapper",
        style: _normalizeStyle(`width: ${_ctx.pdfContentWidth};`),
        ref: "pdfContent"
      }, [
        _renderSlot(_ctx.$slots, "pdf-content", {}, undefined, true)
      ], 4)
    ]),
    _createVNode(_Transition, { name: "transition-anim" }, {
      default: _withCtx(() => [
        (_ctx.pdfFile)
          ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.closePreview()), ["self"]))
              }, "×"),
              _createElementVNode("iframe", {
                src: _ctx.pdfFile,
                width: "100%",
                height: "100%"
              }, null, 8, _hoisted_4)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}