import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["texture", "width", "height", "tint", "x", "y"]
const _hoisted_3 = ["textures", "animation-speed", "width", "height", "tint", "x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Application = _resolveComponent("Application")!

  return (_openBlock(), _createBlock(_component_Application, {
    ref: "pixi",
    width: _ctx.width,
    height: _ctx.height,
    backgroundColor: _ctx.backgroundColor,
    style: { 'touch-action': 'auto' }
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.texture)
        ? (_openBlock(), _createElementBlock("container", _hoisted_1, [
            (!_ctx.isAnimation && !Array.isArray(_ctx.texture))
              ? (_openBlock(), _createElementBlock("sprite", {
                  key: 0,
                  texture: _ctx.texture,
                  width: _ctx.objectWidth * _ctx.scaleFactor,
                  height: _ctx.objectHeight * _ctx.scaleFactor,
                  tint: _ctx.tint,
                  anchor: 0.5,
                  x: _ctx.width / 2,
                  y: _ctx.height / 2
                }, null, 8, _hoisted_2))
              : (_ctx.isAnimationLoaded)
                ? (_openBlock(), _createElementBlock("animated-sprite", {
                    key: 1,
                    textures: _ctx.animationSequence,
                    "animation-speed": _ctx.animationSpeed,
                    width: _ctx.objectWidth * _ctx.scaleFactor,
                    height: _ctx.objectHeight * _ctx.scaleFactor,
                    anchor: 0.5,
                    tint: _ctx.tint,
                    x: _ctx.width / 2,
                    y: _ctx.height / 2,
                    onRender: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.renderAnimation && _ctx.renderAnimation(...args))),
                    onFrameChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.animationFrameChanged && _ctx.animationFrameChanged(...args)))
                  }, null, 40, _hoisted_3))
                : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "overlay")
    ]),
    _: 3
  }, 8, ["width", "height", "backgroundColor"]))
}