import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0f87e98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dialog__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_time_picker = _resolveComponent("el-time-picker")!
  const _component_FromSubmitItem = _resolveComponent("FromSubmitItem")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createBlock(_component_ValidationForm, {
    "form-data": _ctx.formData,
    "use-default-submit": false,
    onSubmitDataValid: _ctx.save,
    onReset: _ctx.reset
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.showSettings,
        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showSettings) = $event)),
        width: "calc(var(--app-width) * 0.8)",
        "before-close": _ctx.handleClose
      }, {
        header: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('moderator.organism.settings.timerSettings.header')), 1),
          _createVNode(_component_el_radio_group, {
            modelValue: _ctx.activeState,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeState) = $event)),
            size: "large"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_radio_button, {
                label: _ctx.$t('moderator.organism.settings.timerSettings.off'),
                value: "off"
              }, null, 8, ["label"]),
              _createVNode(_component_el_radio_button, {
                label: _ctx.$t('moderator.organism.settings.timerSettings.on'),
                value: "on"
              }, null, 8, ["label"])
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        footer: _withCtx(() => [
          (_ctx.customTime)
            ? (_openBlock(), _createBlock(_component_FromSubmitItem, {
                key: 0,
                "form-state-message": _ctx.formData.stateMessage,
                "submit-label-key": "moderator.organism.settings.timerSettings.submit"
              }, null, 8, ["form-state-message"]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          (!_ctx.customTime && _ctx.isActive)
            ? (_openBlock(), _createBlock(_component_el_form_item, {
                key: 0,
                prop: "remindingTime",
                label: _ctx.$t('moderator.organism.settings.timerSettings.time'),
                rules: [
          _ctx.defaultFormRules.ruleRequiredIf(_ctx.formData.hasTimeLimit),
          _ctx.defaultFormRules.ruleDate,
        ],
                class: "timeOptions"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: _ctx.isActive && !_ctx.formData.hasTimeLimit ? 'primary' : 'info',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTimeLimit(false)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "infinity" })
                    ]),
                    _: 1
                  }, 8, ["type"]),
                  _createVNode(_component_el_button, {
                    type: "info",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setTimeLimit(true, 60)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 1:00 ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "info",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setTimeLimit(true, 5 * 60)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 5:00 ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "info",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setTimeLimit(true, 10 * 60)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 10:00 ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "info",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setTimeLimit(true, 15 * 60)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" 15:00 ")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: _ctx.isActive && _ctx.formData.hasTimeLimit ? 'primary' : 'info',
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setTimeLimit(true)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.settings.timerSettings.custom')), 1)
                    ]),
                    _: 1
                  }, 8, ["type"])
                ]),
                _: 1
              }, 8, ["label", "rules"]))
            : (_ctx.customTime && _ctx.isActive)
              ? (_openBlock(), _createBlock(_component_el_form_item, {
                  key: 1,
                  prop: "remindingTime",
                  label: _ctx.$t('moderator.organism.settings.timerSettings.time'),
                  rules: [
          _ctx.defaultFormRules.ruleRequiredIf(_ctx.formData.hasTimeLimit),
          _ctx.defaultFormRules.ruleDate,
        ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_time_picker, {
                      class: "level-item",
                      modelValue: _ctx.formData.remindingTime,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.remindingTime) = $event)),
                      disabled: !_ctx.formData.hasTimeLimit,
                      style: _normalizeStyle({ display: _ctx.formData.hasTimeLimit ? 'block' : 'none' })
                    }, null, 8, ["modelValue", "disabled", "style"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modelValue", "before-close"])
    ]),
    _: 1
  }, 8, ["form-data", "onSubmitDataValid", "onReset"]))
}