import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f595338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stack" }
const _hoisted_2 = { class: "edit stack__container stack__container__image" }
const _hoisted_3 = { class: "stack__content" }
const _hoisted_4 = {
  key: 0,
  class: "empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageUploader = _resolveComponent("ImageUploader")!
  const _component_LinkSettings = _resolveComponent("LinkSettings")!
  const _component_DrawingUpload = _resolveComponent("DrawingUpload")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_MediaViewer = _resolveComponent("MediaViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ImageUploader, {
      "show-modal": _ctx.showUploadDialog,
      "onUpdate:showModal": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showUploadDialog) = $event)),
      modelValue: _ctx.base64ImageUrl,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.base64ImageUrl) = $event)),
      onImageChanged: _ctx.imageChanged
    }, null, 8, ["show-modal", "modelValue", "onImageChanged"]),
    _createVNode(_component_LinkSettings, {
      "show-modal": _ctx.showLinkInput,
      "onUpdate:showModal": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showLinkInput) = $event)),
      link: _ctx.editLink,
      "onUpdate:link": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editLink) = $event)),
      onImageChanged: _ctx.imageChanged
    }, null, 8, ["show-modal", "link", "onImageChanged"]),
    _createVNode(_component_DrawingUpload, {
      "show-modal": _ctx.showDrawingInput,
      "onUpdate:showModal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showDrawingInput) = $event)),
      modelValue: _ctx.base64ImageUrl,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.base64ImageUrl) = $event)),
      onImageChanged: _ctx.imageChanged
    }, null, 8, ["show-modal", "modelValue", "onImageChanged"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_ctx.link && !_ctx.image)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_font_awesome_icon, { icon: "plus" }),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('moderator.atom.imagePicker.add')), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_MediaViewer, {
          src: _ctx.image ?? _ctx.link,
          fit: "contain"
        }, null, 8, ["src"])
      ]),
      (_ctx.useEditOverlay)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["stack__action", { gridOverlay: _ctx.image || _ctx.link }]),
            onTransitionend: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleActions && _ctx.toggleActions(...args))),
            "on:webkitTransitionEnd": _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.toggleActions && _ctx.toggleActions(...args)))
          }, [
            _createElementVNode("span", {
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.editLinkInput && _ctx.editLinkInput(...args))),
              class: _normalizeClass({ gridItem: _ctx.image || _ctx.link })
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "link" })
            ], 2),
            _createElementVNode("span", {
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args))),
              class: _normalizeClass({ gridItem: _ctx.image || _ctx.link })
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "upload" })
            ], 2),
            _createElementVNode("span", {
              onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.uploadDrawing && _ctx.uploadDrawing(...args))),
              class: _normalizeClass({ gridItem: _ctx.image || _ctx.link })
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "pencil" })
            ], 2),
            (_ctx.image || _ctx.link)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.deleteImage && _ctx.deleteImage(...args))),
                  class: "gridItem"
                }, [
                  _createVNode(_component_font_awesome_icon, { icon: "trash" })
                ]))
              : _createCommentVNode("", true)
          ], 34))
        : _createCommentVNode("", true)
    ])
  ]))
}