import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ff3d99d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "media video"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_ctx.idea?.image)
    ? (_openBlock(), _createBlock(_component_el_image, {
        key: 0,
        src: _ctx.idea?.image,
        class: _normalizeClass(["image", _ctx.cssClass]),
        alt: "",
        "preview-src-list": [_ctx.idea?.image],
        "hide-on-click-modal": true,
        fit: _ctx.fit
      }, null, 8, ["src", "class", "preview-src-list", "fit"]))
    : (_ctx.isLinkVideo(_ctx.idea?.link))
      ? (_openBlock(), _createElementBlock("figure", _hoisted_1, [
          _createElementVNode("iframe", {
            src: _ctx.convertToEmbed(_ctx.idea?.link),
            height: "100%",
            width: "100%",
            allow: "fullscreen"
          }, null, 8, _hoisted_2)
        ]))
      : (_ctx.idea?.link && !_ctx.idea?.image)
        ? (_openBlock(), _createBlock(_component_el_image, {
            key: 2,
            src: _ctx.idea?.link,
            class: _normalizeClass(["image", _ctx.cssClass]),
            alt: "",
            "preview-src-list": _ctx.allowImagePreview ? [_ctx.idea?.link] : [],
            "hide-on-click-modal": true,
            fit: _ctx.fit
          }, null, 8, ["src", "class", "preview-src-list", "fit"]))
        : _createCommentVNode("", true)
}