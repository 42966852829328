import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45061059"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "register" }
const _hoisted_2 = { class: "register__bg-section full-height flex-column centered" }
const _hoisted_3 = { class: "heading heading--medium heading--white" }
const _hoisted_4 = { class: "register__text" }
const _hoisted_5 = { class: "full-height flex-column centered" }
const _hoisted_6 = { class: "register__content" }
const _hoisted_7 = { class: "heading heading--medium" }
const _hoisted_8 = { class: "register__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('moderator.view.register.login.header')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('moderator.view.register.login.info')), 1),
      _createVNode(_component_router_link, { to: "login" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            type: "info",
            class: "outline"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.register.login.submit')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('moderator.view.register.header')), 1),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('moderator.view.register.info')), 1),
        _createVNode(_component_ValidationForm, {
          "form-data": _ctx.formData,
          "submit-label-key": "moderator.view.register.submit",
          onSubmitDataValid: _ctx.save,
          ref: "dataForm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('moderator.view.register.email'),
              prop: "email",
              rules: [_ctx.defaultFormRules.ruleRequired, _ctx.defaultFormRules.ruleEmail]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "email",
                  name: "email",
                  autocomplete: "on",
                  placeholder: _ctx.$t('moderator.view.register.emailInfo'),
                  modelValue: _ctx.formData.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "rules"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('moderator.view.register.password'),
              prop: "password",
              rules: [
              _ctx.defaultFormRules.ruleRequired,
              _ctx.defaultFormRules.rulePassword,
              _ctx.defaultFormRules.ruleToShort(8),
              _ctx.defaultFormRules.ruleToLong(255),
              _ctx.defaultFormRules.ruleTrigger(_ctx.dataForm, 'passwordRepeat'),
            ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  placeholder: _ctx.$t('moderator.view.register.passwordInfo'),
                  modelValue: _ctx.formData.password,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.password) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "rules"]),
            _createVNode(_component_el_form_item, {
              label: _ctx.$t('moderator.view.register.passwordConform'),
              prop: "passwordRepeat",
              rules: [
              _ctx.defaultFormRules.ruleRequired,
              _ctx.defaultFormRules.rulePassword,
              _ctx.defaultFormRules.ruleToShort(8),
              _ctx.defaultFormRules.ruleToLong(255),
              _ctx.defaultFormRules.ruleMatch(_ctx.formData.password, 'passwordNotMatch'),
            ]
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "password",
                  placeholder: _ctx.$t('moderator.view.register.passwordConformInfo'),
                  modelValue: _ctx.formData.passwordRepeat,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.passwordRepeat) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "rules"])
          ]),
          _: 1
        }, 8, ["form-data", "onSubmitDataValid"])
      ])
    ])
  ]))
}