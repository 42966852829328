import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a3eef3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "el-dialog__title" }
const _hoisted_2 = ["id", "rowspan"]
const _hoisted_3 = { class: "task-card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_slider = _resolveComponent("el-slider")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDialog) = $event)),
    "before-close": _ctx.handleClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('moderator.organism.settings.topicDependencySettings.header')), 1)
    ]),
    default: _withCtx(() => [
      _createElementVNode("table", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableTasks, (row, index) => {
          return (_openBlock(), _createBlock(_component_draggable, {
            key: index,
            modelValue: _ctx.tableTasks[index],
            "onUpdate:modelValue": ($event: any) => ((_ctx.tableTasks[index]) = $event),
            group: "task",
            "item-key": "id",
            onChange: ($event: any) => (_ctx.dragDone($event, index)),
            tag: "tr",
            class: "dependency-row"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (element) => {
                return (_openBlock(), _createElementBlock("td", {
                  id: element.id,
                  key: element.id,
                  rowspan: element.dependency.duration,
                  style: _normalizeStyle({ '--rowspan': element.dependency.duration })
                }, [
                  _createVNode(_component_el_card, {
                    class: "task-card",
                    style: _normalizeStyle({
              '--task-color': _ctx.getColorOfType(element.taskType.toLowerCase()),
            })
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_slider, {
                        vertical: "",
                        max: -1,
                        min: -(element.dependency.duration + 1),
                        "show-stops": "",
                        modelValue: element.dependency.durationInvert,
                        "onUpdate:modelValue": ($event: any) => ((element.dependency.durationInvert) = $event),
                        "format-tooltip": (value) => -value,
                        "format-value-text": (value) => -value,
                        onChange: ($event: any) => (_ctx.durationChanged(element, $event))
                      }, null, 8, ["min", "modelValue", "onUpdate:modelValue", "format-tooltip", "format-value-text", "onChange"]),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_font_awesome_icon, {
                          icon: _ctx.getIconOfType(element.taskType.toLowerCase())
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(element.name), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["style"])
                ], 12, _hoisted_2))
              }), 128))
            ]),
            _: 2
          }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "before-close"]))
}