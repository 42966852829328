import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72507c16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card__extension" }
const _hoisted_2 = {
  class: "level",
  ref: "item"
}
const _hoisted_3 = { class: "level-left" }
const _hoisted_4 = { class: "twoLineText line-break" }
const _hoisted_5 = { class: "twoLineText line-break" }
const _hoisted_6 = { class: "level-right" }
const _hoisted_7 = { class: "level-item settings" }
const _hoisted_8 = { class: "card__drag level-item" }
const _hoisted_9 = { class: "card__extension__slot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_TutorialStep = _resolveComponent("TutorialStep")!
  const _component_TopicSettings = _resolveComponent("TopicSettings")!
  const _component_TopicStatistic = _resolveComponent("TopicStatistic")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TutorialStep, {
      type: "sessionDetails",
      step: "selectTopic",
      order: 6,
      width: 450,
      placement: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_card, {
          class: "card",
          shadow: "hover",
          "body-style": { padding: '0px' },
          style: _normalizeStyle({
          '--background-color': _ctx.backgroundColor,
        })
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("div", {
                  class: "level-item card__info link",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToDetails && _ctx.goToDetails(...args)))
                }, [
                  _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.topic.order + 1) + ". " + _toDisplayString(_ctx.topic.title), 1),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.topic.description), 1)
                ])
              ]),
              _createElementVNode("span", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_ctx.canModify)
                    ? (_openBlock(), _createBlock(_component_el_dropdown, {
                        key: 0,
                        class: "card__menu",
                        onCommand: _cache[3] || (_cache[3] = ($event: any) => (_ctx.menuItemSelected($event))),
                        trigger: "click"
                      }, {
                        dropdown: _withCtx(() => [
                          _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_dropdown_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ToolTip, {
                                    placement: 'right',
                                    text: 
                          _ctx.shareStateValue
                            ? _ctx.$t(
                                'moderator.organism.settings.topicSettings.deactivateTopic'
                              )
                            : _ctx.$t(
                                'moderator.organism.settings.topicSettings.activateTopic'
                              )
                        
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_switch, {
                                        modelValue: _ctx.shareStateValue,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.shareStateValue) = $event)),
                                        onClick: _ctx.sharedChanged
                                      }, null, 8, ["modelValue", "onClick"])
                                    ]),
                                    _: 1
                                  }, 8, ["text"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, { command: "edit" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ToolTip, {
                                    placement: 'left',
                                    text: 
                          _ctx.$t('moderator.organism.settings.topicSettings.edit')
                        
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "pen" })
                                    ]),
                                    _: 1
                                  }, 8, ["text"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, { command: "clone" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ToolTip, {
                                    placement: 'left',
                                    text: 
                          _ctx.$t('moderator.organism.settings.topicSettings.clone')
                        
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "clone" })
                                    ]),
                                    _: 1
                                  }, 8, ["text"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, { command: "delete" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ToolTip, {
                                    placement: 'left',
                                    text: 
                          _ctx.$t('moderator.organism.settings.topicSettings.delete')
                        
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "trash" })
                                    ]),
                                    _: 1
                                  }, 8, ["text"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_el_dropdown_item, { command: "statistic" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ToolTip, {
                                    placement: 'left',
                                    text: 
                          _ctx.$t(
                            'moderator.organism.settings.topicSettings.statistic'
                          )
                        
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_font_awesome_icon, { icon: "chart-column" })
                                    ]),
                                    _: 1
                                  }, 8, ["text"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("span", {
                            class: "el-dropdown-link",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stopPropagation && _ctx.stopPropagation(...args)))
                          }, [
                            _createVNode(_component_ToolTip, {
                              text: 
                      _ctx.$t('moderator.organism.settings.topicSettings.settings')
                    
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_font_awesome_icon, { icon: "ellipsis-h" })
                              ]),
                              _: 1
                            }, 8, ["text"])
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_ToolTip, {
                    placement: 'left',
                    text: _ctx.$t('moderator.organism.settings.topicSettings.drag')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: "grip-vertical",
                        class: "card__drag__icon"
                      })
                    ]),
                    _: 1
                  }, 8, ["text"])
                ])
              ])
            ], 512)
          ]),
          _: 1
        }, 8, ["style"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_TopicSettings, {
      "show-modal": _ctx.showSettings,
      "onUpdate:showModal": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSettings) = $event)),
      "session-id": _ctx.sessionId,
      "topic-id": _ctx.editingTopicId,
      onTopicUpdated: _ctx.reload
    }, null, 8, ["show-modal", "session-id", "topic-id", "onTopicUpdated"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showStatistic,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showStatistic) = $event)),
      width: "calc(var(--app-width) * 0.8)"
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('moderator.organism.settings.topicSettings.statistic')) + " " + _toDisplayString(_ctx.topic.title), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_TopicStatistic, {
          "topic-id": _ctx.topic.id
        }, null, 8, ["topic-id"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}