import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f7c55ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "module-count"
}
const _hoisted_2 = { class: "module-count__item" }
const _hoisted_3 = { class: "module-count__count" }
const _hoisted_4 = {
  key: 0,
  class: "module-name"
}
const _hoisted_5 = {
  key: 1,
  class: "module-name"
}
const _hoisted_6 = { class: "module-count__item" }
const _hoisted_7 = { class: "module-count__count" }
const _hoisted_8 = {
  key: 0,
  class: "module-name"
}
const _hoisted_9 = {
  key: 1,
  class: "module-name"
}
const _hoisted_10 = { class: "module-count__item" }
const _hoisted_11 = { class: "module-count__count" }
const _hoisted_12 = {
  key: 0,
  class: "module-name"
}
const _hoisted_13 = {
  key: 1,
  class: "module-name"
}
const _hoisted_14 = { class: "module-count__item" }
const _hoisted_15 = { class: "module-count__count" }
const _hoisted_16 = {
  key: 0,
  class: "module-name"
}
const _hoisted_17 = {
  key: 1,
  class: "module-name"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_ctx.session)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.session.topicCount), 1),
          (_ctx.session.topicCount === 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('moderator.molecule.moduleCount.topics')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('moderator.molecule.moduleCountPlural.topics')), 1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.session.taskCount), 1),
          (_ctx.session.taskCount === 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('moderator.molecule.moduleCount.tasks')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('moderator.molecule.moduleCountPlural.tasks')), 1))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.users.length), 1),
          (_ctx.users.length === 1)
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('moderator.molecule.moduleCount.users')), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('moderator.molecule.moduleCountPlural.users')), 1))
        ]),
        _createVNode(_component_el_popover, {
          trigger: "click",
          width: "calc(var(--app-width) * 0.2)"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.participants.length), 1),
              (_ctx.participants.length === 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('moderator.molecule.moduleCount.participants')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('moderator.molecule.moduleCountPlural.participants')), 1))
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_space, {
              wrap: "",
              class: "participant-list"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participants, (participant) => {
                  return (_openBlock(), _createBlock(_component_el_badge, {
                    key: participant.id,
                    value: participant.ideaCount,
                    type: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, {
                        icon: participant.avatar.symbol,
                        style: _normalizeStyle({ color: participant.avatar.color })
                      }, null, 8, ["icon", "style"])
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}