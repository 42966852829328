import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3a8c530"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "media video"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_image = _resolveComponent("el-image")!

  return (_ctx.isLinkVideo(_ctx.src))
    ? (_openBlock(), _createElementBlock("figure", _hoisted_1, [
        _createElementVNode("iframe", {
          src: _ctx.convertToEmbed(_ctx.src),
          height: "100%",
          width: "100%",
          allow: "fullscreen"
        }, null, 8, _hoisted_2)
      ]))
    : (_ctx.src)
      ? (_openBlock(), _createBlock(_component_el_image, {
          key: 1,
          src: _ctx.src,
          class: _normalizeClass(_ctx.cssClass),
          alt: "",
          "preview-src-list": [_ctx.src],
          "hide-on-click-modal": true,
          fit: _ctx.fit
        }, null, 8, ["src", "class", "preview-src-list", "fit"]))
      : _createCommentVNode("", true)
}