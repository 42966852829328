import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1e128c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "confirm__content full-height-header" }
const _hoisted_2 = { class: "profile__email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_ValidationForm = _resolveComponent("ValidationForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('moderator.view.resetPassword.header')), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('moderator.view.resetPassword.info')), 1),
    _createVNode(_component_ValidationForm, {
      "form-data": _ctx.formData,
      "submit-label-key": "moderator.view.resetPassword.submit",
      onSubmitDataValid: _ctx.save,
      ref: "dataForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: _ctx.$t('moderator.view.resetPassword.email'),
          prop: "email",
          rules: [_ctx.defaultFormRules.ruleRequired, _ctx.defaultFormRules.ruleEmail]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.formData.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
              type: "email",
              name: "email",
              autocomplete: "on",
              placeholder: _ctx.$t('moderator.view.resetPassword.emailInfo')
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }, 8, ["label", "rules"])
      ]),
      _: 1
    }, 8, ["form-data", "onSubmitDataValid"])
  ]))
}