import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a046bf9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "link check" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_observe_visibility = _resolveDirective("observe-visibility")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("span", {
      ref: "visibilityObserver",
      id: _ctx.uuid,
      class: "visibilityObserver"
    }, null, 8, _hoisted_1), [
      [_directive_observe_visibility, _ctx.visibilityChanged]
    ]),
    _createVNode(_component_el_popover, {
      placement: _ctx.placement,
      width: _ctx.width,
      trigger: "manual",
      visible: _ctx.showStep
    }, {
      reference: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(`tutorial.${_ctx.type}.${_ctx.step}`)), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stepDone && _ctx.stepDone(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "check" })
          ]),
          _createElementVNode("span", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.skipAll && _ctx.skipAll(...args)))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "stop" })
          ])
        ])
      ]),
      _: 3
    }, 8, ["placement", "width", "visible"])
  ], 64))
}