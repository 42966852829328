import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8fff9b22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_ctx.showProgress)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_progress, {
          "text-inside": true,
          "stroke-width": 26,
          percentage: _ctx.percentage,
          format: _ctx.formatProcess
        }, null, 8, ["percentage", "format"])
      ]))
    : _createCommentVNode("", true)
}