import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d062b34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "add__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_card = _resolveComponent("el-card")!

  return (_openBlock(), _createBlock(_component_el_card, {
    shadow: "hover",
    class: _normalizeClass(["add", { 'add--column': _ctx.isColumn, readonly: !_ctx.isClickable }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNew')))
  }, {
    default: _withCtx(() => [
      (_ctx.displayPlus)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: "plus"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
    ]),
    _: 1
  }, 8, ["class"]))
}