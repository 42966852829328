import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f7302fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile__content full-height-header" }
const _hoisted_2 = { class: "profile__email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ModeratorNavigationLayout = _resolveComponent("ModeratorNavigationLayout")!
  const _component_LanguageSettings = _resolveComponent("LanguageSettings")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModeratorNavigationLayout, {
      currentRouteTitle: _ctx.$t('moderator.molecule.navigation.profile')
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.$t('moderator.view.profile.header')), 1),
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('moderator.view.profile.info')) + " " + _toDisplayString(_ctx.email), 1),
          _createVNode(_component_router_link, { to: "change-password" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "info",
                class: "static-width el-button--submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.profile.changePassword')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "info",
            class: "static-width el-button--submit",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showLanguageSettings = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.profile.changeLanguage')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "info",
            class: "static-width el-button--submit",
            onClick: _ctx.logout
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.profile.submit')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "info",
            class: "static-width el-button--submit",
            onClick: _ctx.deleteUser
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('moderator.view.profile.delete')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["currentRouteTitle"]),
    _createVNode(_component_LanguageSettings, {
      "show-modal": _ctx.showLanguageSettings,
      "onUpdate:showModal": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showLanguageSettings) = $event)),
      isParticipant: false
    }, null, 8, ["show-modal"])
  ], 64))
}